import React, { useState, useEffect } from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  Box,
  DialogContentText,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { Check, Publish, Close as Cross } from "@material-ui/icons";
import FileUpload from "../../components/FileUpload";
import Dialog from "../../components/Dialog";
import saveAs from "file-saver";

export function TransformDataDialog({ onClose, mapping, mutation }) {
  const open = Boolean(mapping);
  const [file, setFile] = useState(null);
  const [dataHasHeaders, setDataHasHeaders] = useState(true);
  const emailEnabled = false;

  useEffect(() => {
    const close = () => {
      setFile(null);
    };

    if (!open) {
      close();
    }

    return close;
  }, [open]);

  return (
    <>
      <MuiDialog
        onClose={() => mutation.reset()}
        open={Boolean(mutation.isLoading || mutation.data || mutation.error)}
      >
        <DialogContent>
          <Box textAlign="center" p={2}>
            {mutation.error ? (
              <>
                <Box fontSize={48} mb={1}>
                  <Cross fontSize="inherit" color="error" />
                </Box>
                <Typography variant="h3">
                  There was a problem trying to transform your data
                </Typography>
                <Box mt={3}>
                  <DialogContentText>
                    Transformers are set up for particular sets of data, please
                    check the data you've uploaded is intended for this
                    transformer. Please get in touch if you still run into
                    problems using this transformer.
                  </DialogContentText>
                </Box>
              </>
            ) : (
              Boolean(mutation.isLoading || mutation.data) && (
                <>
                  <Typography variant="h3">
                    {`Your data is ${
                      mutation.isLoading ? "loading..." : "ready!"
                    }`}
                  </Typography>
                  {mutation.isLoading && (
                    <Box mt={2}>
                      {emailEnabled && (
                        <DialogContentText>
                          No need to hang around, we'll email it to you once
                          it's ready!
                        </DialogContentText>
                      )}
                    </Box>
                  )}
                  <Box fontSize={48} my={2}>
                    {mutation.isLoading ? (
                      <CircularProgress size={48} />
                    ) : (
                      <Check fontSize="inherit" color="success" />
                    )}
                  </Box>
                  <Box mt={2}>
                    <Button
                      onClick={() => {
                        saveAs(mutation.data, "transformed.csv");
                        mutation.reset();
                      }}
                      disabled={!mutation.data}
                      color="primary"
                      variant="contained"
                    >
                      Download
                    </Button>
                  </Box>
                </>
              )
            )}
          </Box>
        </DialogContent>
      </MuiDialog>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        title={`Transform Data With ${mapping?.name}`}
        actions={
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(dataHasHeaders)}
                  onChange={() => setDataHasHeaders(!dataHasHeaders)}
                  color="primary"
                />
              }
              label={
                <Typography color="textSecondary">
                  Don't import the first line because they are column headings
                </Typography>
              }
            />
            <Button
              disabled={!file}
              variant="contained"
              color="primary"
              onClick={async () => {
                try {
                  await mutation.mutateAsync({
                    file,
                    mapping,
                    hasHeaders: dataHasHeaders,
                  });
                } catch (_) {}

                onClose();
              }}
            >
              Submit
            </Button>
            <Button onClick={onClose} variant="contained">
              Cancel
            </Button>
          </>
        }
      >
        <DialogContentText>
          Upload your CSV file for transformation.
        </DialogContentText>
        {open && (
          <Box my={2}>
            <FileUpload
              id="upload-transform-mapping"
              accept=".csv"
              onChange={(file) => setFile(file)}
              startIcon={<Publish />}
              label={file ? `File selected: ${file.name}` : "Select File"}
              variant="outlined"
            />
          </Box>
        )}
      </Dialog>
    </>
  );
}
