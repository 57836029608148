import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { LoginModal } from "../components/LoginModal";
import { auth, firestore } from "../firebase";
import { getDocs, query, where, limit, collection } from "firebase/firestore";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useRecoilState } from "recoil";
import { Dashboard } from './Dashboard';
import { userAtom } from "./user";
import Header from './Header';

export function Customer() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [madeUserCheck, setMadeUserCheck] = useState(false);
  const [userState, setUserState] = useRecoilState(userAtom);

  const signInMutation = useMutation(async ({ email, password }) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      enqueueSnackbar(
        "Unable to login, please check your credentials or connection",
        { variant: "error" }
      );
    }
  });

  useEffect(() => {
    const cancel = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.role === "admin") {
          return history.push("/admin");
        }

        const q = query(
          collection(firestore, "users"),
          where("authId", "==", user.uid),
          limit(1)
        );

        const documentSnapshot = await getDocs(q);

        if (documentSnapshot.empty) {
          enqueueSnackbar("There was an error logging in.", {
            variant: "error",
          });

          return signOut(auth);
        }

        const [userDocument] = documentSnapshot.docs;

        setUserState({ id: userDocument.id, ...userDocument.data() });
      } else {
        setUserState(null)
      }

      setMadeUserCheck(true);
    });

    return cancel;
  }, [history, enqueueSnackbar]);

  if (!madeUserCheck) {
    // isLoading?
    return null;
  }

  if (userState) {
    return (
      <>
        <Header />
        <main>
          <Dashboard />
        </main>
      </>
    )
  }

  return (
    <LoginModal
      onSubmit={async (values) => {
        signInMutation.mutateAsync(values);
      }}
    />
  );
}
