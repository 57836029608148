import React, { useMemo, useReducer } from "react";
import { assoc, set, over, lensPath, range, view } from 'ramda';
import {
  Box,
  IconButton,
  makeStyles,
  Select,
  Table as BaseTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import { FirstPage, LastPage, ChevronRight, ChevronLeft } from '@material-ui/icons';
import { TextField } from './TextField';
import useDebounce from '../Admin/ManageMappings/Editor/hooks/useDebounce';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  table: {
    minWidth: 650,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.action.hover,
    zIndex: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const TablePaper = (props) => {
  return <Paper {...props} variant="outlined" />
}
  

const defaultState = {
  search: '',
  paginator: {
    cursor: 0,
    pageSize: 10,
  }
}

const textMatch = (value, search) => String(value).match(new RegExp(search, 'i'));

const cursorLens = lensPath(['paginator', 'cursor']);

export function Table({ columns, rows, rowId = "id", search, paginate, noResults, isLoading }) {
  const classes = useStyles();
  const [state, dispatch] = useReducer((state, fn) => fn(state), defaultState)

  const searchState = useDebounce(state.search, 750)

  const filteredRows = useMemo(() => {
    if (search?.match && searchState) {
      return rows.filter(r => search.match(r, searchState, textMatch))
    }

    return rows
  }, [rows, searchState, search?.match])

  const numberOfPages = Math.ceil(filteredRows.length / state.paginator.pageSize)

  const paginatedRows = useMemo(() => {
    if (paginate) {
      const start = state.paginator.cursor * state.paginator.pageSize;
      const end = start + state.paginator.pageSize;
      return filteredRows.slice(start, end);
    }

    return filteredRows;
  }, [
    filteredRows,
    paginate,
    state.paginator.cursor,
    state.paginator.pageSize,
  ]);

  const renderedRows = paginatedRows;

  return (
    <TableContainer className={classes.container} component={TablePaper}>
      {isLoading && (
        <Box className={classes.loading}>
          <Box p={1} display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Box fontSize="20px" mt={2}>
              Loading...
            </Box>
          </Box>
        </Box>
      )}
      {Boolean(search || paginate) && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          gridGap="10px"
        >
          {search && (
            <TextField
              value={state.search}
              placeholder={search.placeholder ?? "Search"}
              onChange={(e) => dispatch(assoc("search", e.target.value))}
              search={{ onClear: () => dispatch(assoc("search", "")) }}
              size="small"
              fullWidth={false}
              style={{ width: 350 }}
            />
          )}
          {paginate && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gridGap="3px"
            >
              <IconButton disabled={numberOfPages === 1} size="small" onClick={() => dispatch(set(cursorLens, 0))}>
                <FirstPage />
              </IconButton>
              <IconButton
                size="small"
                disabled={view(cursorLens, state) === 0}
                onClick={() => dispatch(over(cursorLens, (n) => n - 1))}
              >
                <ChevronLeft />
              </IconButton>
              <Select
                value={state.paginator.cursor}
                onChange={(e) => dispatch(set(cursorLens, e.target.value))}
              >
                {range(0, numberOfPages).map((n) => (
                  <option key={n} value={n}>
                    {n + 1}
                  </option>
                ))}
              </Select>
              <IconButton
                size="small"
                disabled={view(cursorLens, state) === numberOfPages - 1}
                onClick={() => dispatch(over(cursorLens, (n) => n + 1))}
              >
                <ChevronRight />
              </IconButton>
              <IconButton
                disabled={numberOfPages === 1} 
                onClick={() => dispatch(set(cursorLens, numberOfPages - 1))}
                size="small"
              >
                <LastPage />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
      <BaseTable className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((c) => (
              <TableCell align={c.align} key={c.field}>
                {c.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length ? (
            renderedRows.reduce((acc, row, i) => {
              return acc.concat(
                <TableRow key={row[rowId] ?? i}>
                  {columns.map((c) => (
                    <TableCell align={c.align} key={c.field}>
                      {c.render?.(c, row) ?? row[c.field]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            }, [])
          ) : (
            <TableRow>
              <TableCell>{noResults ?? "No results"}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </BaseTable>
    </TableContainer>
  );
}
