import { useReducer } from 'react'
import { useActions } from '../useAction'
import { reducer, getColumns, initialState } from './reducer'

const actionTypes = [
  'initialiseColumns',
  'toggleColumnVisibility',
  'renameColumn',
  'addColumn',
  'removeColumn',
]

export default function useColumnEditor(suppliedInitialState = initialState) {
  const [columnsState, dispatch] = useReducer(reducer, suppliedInitialState)
  const columns = getColumns(columnsState)
  const actions = useActions(dispatch, actionTypes)

  return {
    ...actions,
    columnsState,
    columns,
  }
}

