import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  input: {
    display: "none",
  },
});

export default function UploadButton({
  id,
  accept,
  label,
  onChange,
  variant = "contained",
  ...props
}) {
  const classes = useStyles();

  function onFileInputChange(filelist) {
    if (filelist.length) {
      onChange(filelist[0]);
    } else {
      onChange(null);
    }
  }

  return (
    <div>
      <input
        accept={accept}
        className={classes.input}
        id={id}
        onChange={(e) => onFileInputChange(e.target.files)}
        type="file"
      />
      <label htmlFor={id}>
        <Button variant={variant} color="primary" component="span" {...props}>
          {label}
        </Button>
      </label>
    </div>
  );
}
