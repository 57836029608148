import { createTheme as createMuiTheme } from "@material-ui/core";

const rem = (scale) => scale * 16;

export default function createTheme() {
  const config = {
    typography: {
      fontSize: rem(1),
      h1: {
        fontSize: rem(3.052),
        fontWeight: 500,
      },
      h2: {
        fontSize: rem(2.441),
        fontWeight: 400,
      },
      h3: {
        fontSize: rem(1.953),
      },
      h4: {
        fontSize: rem(1.563),
      },
      h5: {
        fontSize: rem(1.25),
        fontWeight: 500,
      },
    },
  };

  const theme = createMuiTheme(config);

  return theme;
}
