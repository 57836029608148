import React from "react";
import Button from './Button'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

export function ConfirmDialog({ open, title, message, action, mutation, onClose }) {
  return (
    <Dialog maxWidth="md" aria-labelledby="confirm-modal" open={open}>
      <DialogTitle id="confirm-modal">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          isLoading={mutation.isLoading}
          variant="contained"
          color="primary"
          onClick={() => mutation.mutateAsync()}
        >
          {action}
        </Button>
        <Button
          onClick={() => onClose()}
          disabled={mutation.isLoading}
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
