import React, {useState} from 'react'
import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from 'react-query';
import { firestore } from "../firebase";
import { doc, setDoc, getDocs, getDoc, collection, writeBatch } from "firebase/firestore";

function MappingTags({ mappingId }) {
  const [state, setState] = useState([]);

  const { data: tags } = useQuery({
    queryKey: ["mapping-tags"],
    queryFn: async () => {
      const tags = [];
      const querySnapshot = await getDocs(collection(firestore, "mapping-tags"));

      querySnapshot.forEach((doc) => tags.push(doc.id));

      return tags;
    },
    initialData: [],
  });

  useQuery(["mapping", mappingId, tags], async () => {
    const docSnapshot = await getDoc(
      doc(firestore, "mappings", mappingId)
    );

    const m = { id: docSnapshot.id, ...docSnapshot.data() } as { id: string; tags?: string[] }

    if (Array.isArray(m.tags)) {
      setState(m.tags.filter((t) => tags.includes(t)));
    }

    return m;
  });

   return (
     <>
       <Autocomplete
         multiple
         options={tags}
         value={state}
         freeSolo
         onChange={async (e, updatedTags: string[], actionType) => {
           setState(updatedTags);

           const batch = writeBatch(firestore);

           updatedTags.forEach((tag: string) => {
             const ref = doc(firestore, "mapping-tags", tag);
             batch.set(ref, { name: tag });
           });

           await batch.commit();

           setDoc(
             doc(firestore, "mappings", mappingId),
             { tags: updatedTags },
             { merge: true }
           );
         }}
         renderTags={(value, getTagProps) => {
           return value.map((option, index) => (
             <Chip
               variant="outlined"
               label={option}
               {...getTagProps({ index })}
             />
           ));
         }}
         renderInput={(params) => (
           <TextField {...params} variant="outlined" label="Tags" />
         )}
       />
     </>
   );
}

export default MappingTags
