import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 3),
    maxWidth: theme.breakpoints.values.sm,
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      marginRight: theme.spacing(2),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  margin: {
    margin: theme.spacing(2, 0, 1),
  },
}));


