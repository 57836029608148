import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useSnackbar } from "notistack";
import {
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  browserLocalPersistence,
} from "firebase/auth";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { AccountTree, People } from "@material-ui/icons";
import { ManageCustomers } from "./ManageCustomers";
import { ManageMappings } from "./ManageMappings";
import { ButtonLink } from "../components/ButtonLink";
import { LoginModal } from "../components/LoginModal";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between",
  },
  menu: {
    "& > *": {
      marginRight: theme.spacing(2),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
}));

export function Admin() {
  const classes = useStyles();
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [madeUserCheck, setMadeUserCheck] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const failedSignin = () => enqueueSnackbar("Sign in attempt failed", { variant: "error" });

  useEffect(() => {
    const cancel = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const { claims } = await user.getIdTokenResult();
        if (claims.role === "admin") {
          setUserIsAdmin(true);
        } else {
          setUserIsAdmin(false);
          signOut(auth);
          history.push('/app');
        }
      } else {
        setUserIsAdmin(false);
      }

      setMadeUserCheck(true);
    });

    return cancel;
  }, []);

  if (!madeUserCheck) {
    return null;
  }

  if (!userIsAdmin) {
    return (
      <LoginModal
        title="Login - Admin"
        onSubmit={async ({ email, password }) => {
          try {
            await setPersistence(auth, browserLocalPersistence);
            await signInWithEmailAndPassword(auth, email, password);
          } catch (error) {
            failedSignin();
          }
        }}
      />
    );
  }

  return (
    <>
      <AppBar color="default" position="static">
        <Container maxWidth="lg">
          <Toolbar disableGutters className={classes.toolbar}>
            <Typography variant="h6" component="div">
              Boss My Data - Admin
            </Typography>
            <div className={classes.menu}>
              <ButtonLink
                to="/admin/customers"
                startIcon={<People fontSize="inherit" />}
                color="inherit"
              >
                Customers
              </ButtonLink>
              <ButtonLink
                to="/admin/mappings"
                startIcon={<AccountTree fontSize="inherit" />}
                color="inherit"
              >
                Mappings
              </ButtonLink>
              <Button
                onClick={() => {
                  signOut(auth);
                  window.location.href = '/admin'
                }}
                color="inherit"
              >
                Sign out
              </Button>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <Container className={classes.container} maxWidth="lg">
        <Switch>
          <Route path="/admin/customers">
            <ManageCustomers />
          </Route>
          <Route path="/admin/mappings">
            <ManageMappings />
          </Route>
          <Route>
            <Redirect to="/admin/customers" />
          </Route>
        </Switch>
      </Container>
    </>
  );
}
