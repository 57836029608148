import { atom, selector } from 'recoil'
import * as math from './math'
import { CellTransformException } from './formulas'
import { assocPath, mergeWithKey } from 'ramda'

export const aggregateState = atom({
  key: 'aggregateState',
  default: {}
})

export const aggregationExpression = selector({
  key: 'aggregationExpression',
  get: ({ get }) => mapInputToAggregation(get(aggregateState))
});

export function groupColumn(state, { group, field }) {
  return { ...state, [field]: { group, expression: '' } }
}

export function updateExpression(state, { expression, field }) {
  return assocPath([field, 'expression'], expression , state)
}

export function aggregateRows({ group, aggregate }, rows) {
  const groupedColumns = Object.entries(group)

  const grouped = rows.reduce((acc, row) => {
    const allowedGroupings = groupedColumns
      .filter(([groupedColumnKey, config]) => {
        if (config.when) {
          const value = row[groupedColumnKey]
          return config.when(value, row)
        } else {
          return true
        }
      });

    const groupedIndex = allowedGroupings.length !== groupedColumns.length
      ? ''
      : allowedGroupings
        .map(([groupedColumnKey]) => {
          return groupedColumnKey + ':' + row[groupedColumnKey]
        })
        .join();

    if (!acc[groupedIndex]) {
      acc[groupedIndex] = []
    }

    acc[groupedIndex].push(row)

    return acc
  }, {})

  const { '': rest = [], ...toAgggregate } = grouped

  const rowReducer = mergeWithKey(
    (key, left, right) => aggregate[key]
      ? aggregate[key](left, right)
      : right
  )

  const aggregated = Object.values(toAgggregate)
    .map((rows) => rows.reduce(rowReducer))

  return [ ...aggregated, ...rest ]
}

export function mapInputToAggregation(input) {
  return Object.entries(input).reduce((acc, [field, definition]) => {
    const key = definition.group

    if (key === 'group') {
      const group = {}

      if (definition.expression) {
        group.when = ($cell /* add row to scope */) => {
          try {
            return math.evaluate({ $cell }, definition.expression)
          } catch (e) {
            throw new AggregationGroupingException(e, {
              cell: $cell,
              formula: definition.expression
            })
          }
        }
      }

      acc.shouldAggregate = true
      acc.group[field] = group
    } else { // We are aggregating
      const aggregate = ($acc, $cell) => {
        const isExpessionValid = typeof definition.expression === 'string'
          && definition.expression.length

        const expression = isExpessionValid ? definition.expression : '$cell'

        // add row to the scope
        try {
          return math.evaluate({ $acc, $cell }, expression)
        } catch (e) {
          throw new AggregationGroupingException(e, {
            cell: $cell,
            formula: expression,
          })
        }
      }

      acc.aggregate[field] = aggregate
    }
    
    return acc
  }, { group: {}, aggregate: {} })
}

export class AggregationGroupingException extends CellTransformException {
  constructor(error, meta) {
    super(error, meta)
    this.name = "AggregationException"
  } 
}

export class AggregationException extends CellTransformException {
  constructor(error, meta) {
    super(error, meta)
    this.name = "AggregationException"
  } 
}
