import React from "react";
import Button from '../../../components/Button'
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useMutation } from "react-query";
import { apiFetch } from "../../../api";

export function DeleteUserDialog({ id, back, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const deleteUserMutation = useMutation(async () => {
    try {
      await apiFetch(`/users/${id}`, { method: "DELETE" });
      enqueueSnackbar("User deleted", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      history.push(back);
    }
  });

  return (
    <Dialog maxWidth="md" aria-labelledby="delete-user-title" open>
      <DialogTitle id="delete-user-title">Delete User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action can not be reversed, you would have to create a new user.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          isLoading={deleteUserMutation.isLoading}
          variant="contained"
          color="primary"
          onClick={() => deleteUserMutation.mutateAsync()}
        >
          Delete
        </Button>
        <Button
          onClick={() => onClose()}
          disabled={deleteUserMutation.isLoading}
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
