import React from "react";
import { forwardRef } from "react";
import { TextField as BaseTextField } from "@material-ui/core";
import { useField } from "formik";
import { Clear, Search } from "@material-ui/icons"
import { IconButton, InputAdornment } from "@material-ui/core";

export const TextField = forwardRef(({ search, ...props }, ref) => {
  const searchProps = search && {
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={() => search.onClear()} size="small">
            <Clear />
          </IconButton>
        </InputAdornment>
      ),
    }
  };

  return (
    <BaseTextField
      ref={ref}
      InputLabelProps={{
        shrink: true,
        disableAnimation: true,
      }}
      variant="outlined"
      margin="normal"
      fullWidth
      {...searchProps}
      {...props}
    />
  );
});

export const Field = forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const error = Boolean(meta.touched && meta.error);
  return (
    <TextField
      ref={ref}
      {...field}
      error={error}
      helperText={error ? meta.error : ''}
      autoComplete="off"
      {...props}
    />
  );
});
