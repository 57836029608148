import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ListUsers from './ListUsers';
import { AddUser } from './AddUser';
import { ManageUser } from './ManageUser';

export function ManageCustomers() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={path + "/add"}>
        <AddUser back={url} />
      </Route>
      <Route path={path + "/manage/:userId"}>
        <ManageUser back={url} />
      </Route>
      <Route>
        <ListUsers />
      </Route>
    </Switch>
  );
}
