import React from "react";
import { RecoilRoot } from "recoil";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Customer } from "./Customer";
import { Admin } from "./Admin";
import { Signup } from "./Signup";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@material-ui/core";
import createTheme from "./theme";

const queryClient = new QueryClient({
  defaultOptions: { queries: { cacheTime: 0 } },
});

function App() {
  return (
    <ThemeProvider theme={createTheme()}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            maxSnack={1}
          >
            <React.Suspense fallback={<div>Loading...</div>}>
              <BrowserRouter>
                <Switch>
                  <Route path="/admin">
                    <Admin />
                  </Route>
                  <Route path="/signup">
                    <Signup />
                  </Route>
                  <Route path="/app">
                    <Customer />
                  </Route>
                  <Route>
                    <Redirect to="/app" />
                  </Route>
                </Switch>
              </BrowserRouter>
            </React.Suspense>
          </SnackbarProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
