import { useMemo } from 'react'

export const useAction = (dispatch, type) => useMemo(
  () => payload => { dispatch({ type, payload }) },
  [dispatch, type]
)

export const useActions = (dispatch, types) => useMemo(
  () => types.reduce((actions, type) => ({
    ...actions,
    [type]: (payload) => dispatch({ type, payload })
  }), {}),
  [dispatch, types]
)
