import React from "react";
import { apiFetch } from "./api";
import { useQuery, useMutation } from "react-query";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@material-ui/core";
import qs from "query-string";
import { signInWithEmailAndPassword, signInAnonymously } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import { TextField, Field } from "./components/TextField";
import Button from "./components/Button";
import { firestore, auth } from "./firebase";
import { Formik, Form } from "formik";
import { Redirect, useHistory } from "react-router-dom";
import * as yup from "yup";

const password = yup.string().label("Password").default("").required();

const validationSchema = yup.object({
  password: password.min(6).max(24),
  confirmPassword: password.test(
    "must-match",
    "Passwords must match",
    (value, context) => value === context.parent.password
  ),
});

export function Signup() {
  const history = useHistory();
  const params = qs.parse(history.location.search);

  const userQuery = useQuery(`user-for-signup/${params.userId}`, async () => {
    await signInAnonymously(auth);

    const documentSnapshot = await getDoc(
      doc(firestore, "users", params.userId)
    );

    if (!documentSnapshot.exists()) {
      return history.push('/')
    }

    return {
      authId: documentSnapshot.get("authId"),
      email: documentSnapshot.get("email"),
    };
  });

  const setPasswordMutation = useMutation(async (values) => {
    try {
      await apiFetch(
        `/users/${params.userId}/set-password`,
        {
          method: 'POST',
          body: JSON.stringify({ password: values.password })
        }
      )

      await signInWithEmailAndPassword(auth, userQuery.data.email, values.password)
    } catch (_) {
    } finally {
      history.push('/');
    }
  })

  const formikProps = {
    initialValues: validationSchema.getDefaultFromShape(),
    validationSchema,
    validateOnMount: false,
    onSubmit: async (values) => {
      return setPasswordMutation.mutateAsync(values)
    },
  };

  if (userQuery.isLoading) {
    return null;
  }

  if (userQuery.isError || userQuery.data?.authId) {
    return <Redirect to="/" />;
  }

  return (
    <Dialog aria-labelledby="register-title" open>
      <DialogTitle id="register-title">Register</DialogTitle>
        <Formik {...formikProps}>
          {() => (
            <Form>
              <DialogContent>
                <DialogContentText>
                  Set a password for sign in.
                </DialogContentText>
                <TextField
                  disabled
                  name="email"
                  type="email"
                  label="Email"
                  value={userQuery.data.email}
                />
                <Field disabled={setPasswordMutation.isLoading} name="password" type="password" label="Password" />
                <Field
                  disabled={setPasswordMutation.isLoading} 
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                />
              </DialogContent>
              <DialogActions>
                <Button isLoading={setPasswordMutation.isLoading} type="submit" color="primary" variant="contained">
                  Set Password
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
    </Dialog>
  );
}
