import React, { useState, useReducer, useEffect } from 'react'
import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { useRecoilValue } from 'recoil'
import { Box, TextField, FormControlLabel, Checkbox, Button, DialogTitle, DialogActions, DialogContent, Dialog } from '@material-ui/core'
import { aggregationExpression, aggregateRows } from '../aggregate'
import TransformError from '../components/TransformError'
import { applyFormulas } from '../formulas' // eslint-disable-line
import {Alert, AlertTitle} from '@material-ui/lab'
import { Table } from '../../../../components/Table'

function reducer(state, action) {
  switch (action.type) {
    case 'pending':
      return {
        ...state,
        loading: true,
      }
    case 'fullfilled':
      return {
        data: action.payload,
        loading: false,
        error: undefined,
      }
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case 'finally':
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default function OutputPreviewTable(props) {
  const { rowFormulae, data, columnsState } = props
  const [lastTransformTime, setLastTransformTime] = useState();
  const [dependencyChangeTime, setDependencyChangeTime] = useState();
  const [showExportDialog, setShowExportDialog] = useState(false);
  const aggregationMapping = useRecoilValue(aggregationExpression);

  const [state, dispatch] = useReducer(reducer, {
    data: [],
    loading: false,
    error: undefined
  })

  useEffect(() => {
    setDependencyChangeTime(Date.now());
  }, [rowFormulae, columnsState, aggregationMapping])

  function exportToCSV(config, filename) {
    const data = state.data.length ? state.data : props.data
    const exportData = data.map(row => {
      const { tableData, ...rowData } = row
      return Object.fromEntries(Object.entries(rowData).map(([field, value]) => [
        props.columnsState[field].renamed ?? props.columnsState[field].title,
        value
      ]))
    })

    const csv = Papa.unparse(exportData, config)
    const blob = new Blob([csv], { type: 'text/csv' })
    saveAs(blob, `${filename}.csv`)
  }

  async function runTransform() {
    try {
      let transformedData = applyFormulas(
        rowFormulae,
        columnsState,
        data, 
      )

      if (aggregationMapping.shouldAggregate) {
        transformedData = aggregateRows(
          aggregationMapping,
          transformedData.length ? transformedData : data
        )
      }

      dispatch({ type: 'fullfilled', payload: transformedData })
      props.setError(null)
      setLastTransformTime(Date.now());
    } catch (error) {
      console.error(error)
      dispatch({ type: 'error', payload: error })
      props.setError(error)
    } finally {
      dispatch({ type: 'finally' })
    }
  }

  const buttons = (
    <>
      <Box display="flex" gridGap="10px" mb={2}>
        <Button
          disabled={state.loading}
          onClick={runTransform}
          fullWidth
          color="primary"
          variant="contained"
        >
          Run transform
        </Button>
        <Button
          disabled={
            state.loading ||
            !lastTransformTime ||
            dependencyChangeTime > lastTransformTime
          }
          onClick={() => setShowExportDialog(true)}
          fullWidth
          color="primary"
          variant="contained"
        >
          Export to csv
        </Button>
      </Box>
    </>
  );

  if (state.error) {
    return (
      <>
        <Box mb={2}>
          <TransformError transformError={state.error} />
        </Box>
        {buttons}
      </>
    )
  }

  return (
    <>
      {buttons}
      {!state.data?.length ? (
        <Alert severity="warning">
          <AlertTitle>Run transform to see results</AlertTitle>
        </Alert>
      ) : (
        <>
          {dependencyChangeTime > lastTransformTime && (
            <Box mb={2}>
              <Alert severity="warning">
                <AlertTitle>
                  Output may not be showing latest changes.
                </AlertTitle>
                Run transform to see latest changes.
              </Alert>
            </Box>
          )}
          <Table
            isLoading={state.loading}
            search={{
              match: (row, searchString, textMatch) => {
                return Object.values(row).some((val) =>
                  textMatch(val, searchString)
                );
              },
            }}
            paginate
            columns={props.columns}
            rows={state.data}
          />
        </>
      )}
      <ExportDialog
        open={showExportDialog}
        onClose={() => setShowExportDialog(false)}
        exportToCSV={exportToCSV}
      />
    </>
  );
}

const now = () => {
  const currentDate = new Date();
  const date = String(currentDate.getDate()).padStart(2, '0')
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const year = currentDate.getFullYear()

  return [date, month, year]
}

function ExportDialog({ open, onClose, exportToCSV }) {
  const [filename, setFilename] = useState('csv-transform-' + now().join('-'))
  const [formState, setFormState] = useState({ header: false, quotes: false })

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>
        Export options
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField fullWidth value={filename} onChange={(e) => setFilename(e.target.value)} label="File name" size="small" variant="outlined" />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                value={formState.header}
                onChange={() => setFormState({
                  ...formState,
                  header: !formState.header
                })}
              />
            }
            label="Include column headers"
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                value={formState.quotes}
                onChange={() => setFormState({
                  ...formState,
                  quotes: !formState.quotes
                })}
              />
            }
            label="Use quotes"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={() => {
            exportToCSV(formState, filename)
            onClose()
          }}
          color="primary"
        >
          Export to CSV
        </Button>
      </DialogActions>
    </Dialog>
  )
}
