import React, { useState, SyntheticEvent } from 'react'
import { Column } from '../hooks/useColumnEditor/reducer'
import { ExpansionPanel as MuiExpansionPanel, ExpansionPanelSummary as MuiExpansionPanelSummary, ExpansionPanelDetails, Paper, InputBase, IconButton, Checkbox, FormControlLabel, Typography, Box, makeStyles, withStyles } from '@material-ui/core'
import { ExpandMore, Delete, Add, ArrowRightAlt, Edit, Check, Close } from '@material-ui/icons'
import { stringToRef } from '../util'

const useStyles = makeStyles(theme => ({
  renameArrow: {
    alignSelf: 'center',
    marginRight: theme.spacing(2),
  },
}))

const ExpansionPanel = withStyles({
  root: {
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  expanded: {},
})(MuiExpansionPanelSummary)


interface Props {
  columns: Column[]
  toggleColumnVisibility: (field: string) => void
    updateColumnName: (payload: { field: string, value: string }) => void
    addColumn: () => void
    removeColumn: (field: string) => void
}

export default function ColumnEditor({
  columns,
  toggleColumnVisibility,
  updateColumnName,
  addColumn,
  removeColumn,
}: Props) {
  const classes = useStyles()
  const [renaming, setRenaming] = useState<string>()
  const [renamingFieldValue, setRenamingFieldValue] = useState('')

  const toggleColumn = (e: SyntheticEvent) => {
    const element = e.target as HTMLInputElement
    toggleColumnVisibility(element.name)
    if (renaming === element.name) {
      setRenaming(undefined)
    }
  }

  const renameColumn = (field: string, value: string) => {
    updateColumnName({ field, value })
    setRenamingFieldValue('')
    setRenaming(undefined)
  }

  const handleAddColumn = () => {
    addColumn()
  }

  // @ts-ignore
  const [visibleColumns, hiddenColumns]: Array<Column[]> = columns.reduce((acc, c) => {
    const [visible, hidden]: Array<Array<Column>> = acc

    if (c.disabled) {
      hidden.push(c)
    } else {
      visible.push(c)
    }

    return [visible, hidden]
  }, [[], []])

  return (
    <>
      <Paper variant="outlined" square>
        <Box p={1} pl={2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            Edit Columns
          </Typography>
          <IconButton aria-label="Add column" onClick={handleAddColumn}>
            <Add />
          </IconButton>
        </Box>
      </Paper>
      {visibleColumns.map(c => (
        <Paper key={c.field} variant="outlined" square>
          <Box display="flex" alignItems="center" justifyContent="space-between" p={1} pl={2}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={!c.disabled} onChange={toggleColumn} name={c.field} />}
              label={
                c.disabled ?
                  <Typography color="textSecondary">{c.title}</Typography>
                  : (renaming === c.field || c.renamed) ?
                    <s>{c.title}</s>
                    : c.title
              }
            />
            <Box display="flex" marginRight="auto" alignSelf="center">
              { (renaming === c.field || c.renamed) &&
              <ArrowRightAlt className={classes.renameArrow} />
              }
              { renaming === c.field &&
                <InputBase
                  value={renamingFieldValue}
                  onChange={e => setRenamingFieldValue(e.target.value)}
                  onKeyPress={e => {
                    e.stopPropagation()
                    if (e.key === 'Enter') {
                      renameColumn(c.field, renamingFieldValue)
                    }
                  }}
                  onClick={e => e.stopPropagation()}
                  onFocus={(e) => {
                    e.stopPropagation()
                    setRenamingFieldValue(c.renamed || '')
                  }}
                  autoFocus
                />
              }
              { (c.renamed && renaming !== c.field) &&
                <Typography>{c.renamed}</Typography>
              }
              { renaming !== c.field && 
                <Box ml={c.renamed ? 2 : 0}>
                  <Typography color="primary">({stringToRef(c.title)})</Typography>
                </Box>
              }
            </Box>
            <Box>
              { renaming === c.field ?
                <Box>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      renameColumn(c.field, renamingFieldValue)
                    }}>
                    <Check fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      renameColumn(c.field, '')
                    }}>
                    <Close fontSize="small" />
                  </IconButton>
                </Box>
                :
                <Box>
                  { c.additional &&
                  <IconButton
                    onClick={e => {
                      e.stopPropagation()
                      removeColumn(c.field)
                    }}>
                    <Delete fontSize="small" />
                  </IconButton>
                  }
                  <IconButton
                    onClick={e => {
                      e.stopPropagation()
                      setRenaming(c.field)
                    }}>
                    <Edit fontSize="small" />
                  </IconButton>
                </Box>
              }
            </Box>
          </Box>
        </Paper>
      ))}
      { Boolean(hiddenColumns.length) && 
        <ExpansionPanel  variant="outlined" square>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>Hidden Columns</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box>
              {hiddenColumns.map((c) => (
                <Box key={c.field} display="flex" alignItems="center">
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={!c.disabled} onChange={toggleColumn} name={c.field} />}
                    label={
                      <>
                        <Typography color="textSecondary" component="span">{c.title} </Typography>
                        <Typography color="primary" variant="body2" component="span"> ({stringToRef(c.title)})</Typography>
                      </>
                    }
                  />
                </Box>
              ))}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      }
    </>
  )
}
