import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { userAtom } from "../user";
import { withAuthToken, apiUrl } from "../../api";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AccountTree } from "@material-ui/icons";
import { getUserMappings } from "../../domain/mappings";
import Papa from "papaparse";
import { TransformDataDialog } from './TransformDataDialog';
import { styles, loadingStyles } from './styles';

const useStyles = makeStyles(styles);
const useLoadingStyles = makeStyles(loadingStyles);

const boolToStr = bool => bool ? 'true' : 'false';

export function Dashboard() {
  const classes = useStyles();
  const loadingClasses = useLoadingStyles();
  const user = useRecoilValue(userAtom);
  const [mappingDialogMapping, setMappingDialogMapping] = useState();
  const mappingsQuery = useQuery("user/mappings", () => getUserMappings(user));

  const transformMutation = useMutation(
    async ({ mapping, file, hasHeaders, writeHeaders }) => {
      const body = await new Promise((resolve) => {
        Papa.parse(file, {
          complete(results) {
            resolve(results);
          },
        });
      });

      const config = await withAuthToken({
        method: "POST",
        responseType: "blob",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await fetch(
        apiUrl(
          `/transform/mapping/${mapping.id}?hasHeaders=${boolToStr(hasHeaders)}&writeHeaders=${boolToStr(writeHeaders)}`
        ),
        config
      );

      if (!res.ok) {
        throw new Error(`Bad response code: ${res.status}`)
      }

      return res.blob();
    }
  );

  if (mappingsQuery.isLoading) {
    const { loadingContainer, ...classes } = loadingClasses;
    return (
      <div className={loadingContainer}>
        <CircularProgress size={64} classes={classes} />
      </div>
    );
  }

  const userHasMappings = Boolean(mappingsQuery.data?.length);

  return (
    <>
      <Container className={classes.container} maxWidth="lg">
        <Paper className={classes.welcome} component="section">
          <Typography variant="h1">Welcome</Typography>
          {userHasMappings ? (
            <>
              <Typography>
                Boss My Data offers a list of data transformers you can use to
                transform and re-format your business's data to allow you to
                easily move data between the tools you use.
              </Typography>
              <Typography>
                Data transformers are made available to you based on what we
                know about which services and tools you use, if you'd like
                additional transformers just get in touch and we can set those
                up for you.
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                You don't have any data transformers available to you yet.
              </Typography>
              <Typography>
                Once we've finished setting up your account you'll be able to
                choose from a list of transformers that you can apply to your
                data.
              </Typography>
            </>
          )}
        </Paper>
        {userHasMappings && (
          <section className={classes.transformers}>
            <Typography variant="h2">Your Data Transformers</Typography>
            <Grid spacing={3} container>
              {mappingsQuery.data.map((mapping, i) => (
                <Grid item key={i} xs={12} sm={6} md={4}>
                  <Paper className={classes.transformer}>
                    <Typography
                      className={classes.transformerName}
                      component="div"
                      variant="h5"
                    >
                      {mapping.name}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setMappingDialogMapping(mapping)}
                      startIcon={<AccountTree />}
                    >
                      Transform
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </section>
        )}
      </Container>
      <TransformDataDialog
        mapping={mappingDialogMapping}
        mutation={transformMutation}
        onClose={() => setMappingDialogMapping(undefined)}
      />
    </>
  );
}
