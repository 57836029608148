import React, { useState } from "react";
import { splitEvery } from "ramda";
import { useSnackbar } from "notistack";
import { Paper, Button, Box } from "@material-ui/core";
import { PageTitle } from "../../../components/PageTitle";
import { ButtonLink } from "../../../components/ButtonLink";
import { TextField } from "../../../components/TextField";
import { useRouteMatch } from "react-router-dom";
import { Table } from "../../../components/Table";
import { firestore } from "../../../firebase";
import {
  doc,
  query,
  where,
  setDoc,
  getDoc,
  getDocs,
  collection,
  documentId,
} from "firebase/firestore";
import { useQuery, useMutation } from "react-query";
import { AddMappingDialog } from "./AddMappingDialog";
import { useStyles } from "./styles";
import { DeleteUserDialog } from "./DeleteDialog";

export function ManageUser({ back }) {
  const [addMappingModalOpen, setAddMappingModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { params } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const {
    data: user,
    refetch: refetchUser,
    isLoading,
  } = useQuery("manage-user/" + params.userId, async () => {
    const documentSnapshot = await getDoc(
      doc(firestore, "users", params.userId)
    );

    const data = { id: documentSnapshot.id, ...documentSnapshot.data() };

    // TODO Refactor to use common function
    const mappings = [];

    if (data.mappings?.length) {
      const batches = splitEvery(10, data.mappings);

      for (const batch of batches) {
        const q = query(
          collection(firestore, "mappings"),
          where(documentId(), "in", batch)
        );

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) =>
          mappings.push({ id: doc.id, ...doc.data() })
        );
      }
    }

    return {
      ...data,
      mappings,
    };
  });

  const removeMappingMutation = useMutation(async (mappingId) => {
    const { id, ...userData } = user;
    try {
      await setDoc(doc(firestore, "users", id), {
        ...userData,
        mappings: user.mappings
          .filter((mapping) => mapping.id !== mappingId)
          .map((mapping) => mapping.id),
      });

      await refetchUser();

      enqueueSnackbar("Removed mapping", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("There was a problem removing the mapping", {
        variant: "error",
      });
    }
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <PageTitle
        title="Manage Customer"
        actions={
          <>
            <Button
              onClick={() => setDeleteDialogOpen(true)}
              variant="outlined"
            >
              <Box color="error.main">Delete</Box>
            </Button>
            <ButtonLink to={back} variant="contained" color="default">
              Back
            </ButtonLink>
          </>
        }
      />
      <Paper className={classes.paper} variant="outlined">
        <TextField value={user?.name ?? ""} disabled name="name" label="Name" />
        <TextField
          value={user?.email ?? ""}
          disabled
          name="email"
          type="email"
          label="Email"
        />
      </Paper>
      <PageTitle
        title="Mappings"
        actions={
          <Button
            onClick={() => setAddMappingModalOpen(true)}
            variant="contained"
            color="primary"
          >
            Add Mapping
          </Button>
        }
      />
      {deleteDialogOpen && (
        <DeleteUserDialog
          id={params.userId}
          back={back}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}
      {addMappingModalOpen && (
        <AddMappingDialog
          user={user}
          onClose={() => setAddMappingModalOpen(false)}
          onAssigned={() => {
            refetchUser();
          }}
        />
      )}
      <Table
        noResults="There are no mappings assigned to this user"
        columns={[
          { field: "name", title: "Name" },
          {
            field: "remove",
            title: "",
            align: "right",
            render(_, row) {
              return (
                <Button onClick={() => removeMappingMutation.mutate(row.id)}>
                  <Box component="span" color="error.main">
                    Remove
                  </Box>
                </Button>
              );
            },
          },
        ]}
        rows={user.mappings ?? []}
      />
    </>
  );
}
