import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ListMappings from './ListMappings';
import AddMapping from './AddMapping';
import { ManageMapping } from './ManageMapping';

export function ManageMappings() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={path + "/add"}>
        <AddMapping back={url} />
      </Route>
      <Route path={path + "/manage/:mappingId"}>
        <ManageMapping back={url} />
      </Route>
      <Route>
        <ListMappings />
      </Route>
    </Switch>
  );
}
