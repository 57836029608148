import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Box } from "@material-ui/core";
import { PersonAdd, Check } from "@material-ui/icons";
import { Table } from "../../components/Table";
import { ButtonLink } from "../../components/ButtonLink";
import { PageTitle } from "../../components/PageTitle";
import { firestore } from "../../firebase";
import { getDocs, collection } from "firebase/firestore";
import { useQuery } from 'react-query';

export default function ListUsers() {
  const { url } = useRouteMatch();

  const { data: users } = useQuery('list-users', async () => {
    const users = [];
    const querySnapshot = await getDocs(collection(firestore, "users"));
    querySnapshot.forEach((doc) => users.push({ id: doc.id, ...doc.data() }));
    return users
  })

  return (
    <>
      <PageTitle
        title="Manage Customers"
        actions={
          <ButtonLink
            to={url + "/add"}
            startIcon={<PersonAdd fontSize="inherit" />}
            variant="contained"
            color="default"
          >
            Add Customer
          </ButtonLink>
        }
      />
      <Table
        search={{
          placeholder: "Filter by name or email",
          match: (user, search, textMatch) => {
            return [user.name, user.email].some((val) =>
              textMatch(val, search)
            );
          },
        }}
        rowId="email"
        columns={[
          { field: "name", title: "Name" },
          { field: "email", title: "Email" },
          {
            field: "active",
            title: "Active",
            align: "center",
            render(_, row) {
              return (
                Boolean(row.authId) && (
                  <Box color="success.main">
                    <Check color="inherit" />
                  </Box>
                )
              );
            },
          },
          {
            field: "manage",
            title: "",
            align: "right",
            render(_, row) {
              return (
                <ButtonLink to={url + "/manage/" + row.id}>Manage</ButtonLink>
              );
            },
          },
        ]}
        rows={users ?? []}
      />
    </>
  );
}
