import { alphabetizeIndex } from '../../util'

export interface Column {
  field: string
  index: number
  title: string
  renamed?: string
  disabled?: boolean
  additional?: boolean
}

export interface Action {
  type: string,
  payload?: any
}

export type Columns = Record<string | number, Column>

export const action = (type: string, payload: any): Action => ({ type, payload })

export const initialState: Columns = {}

export function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case 'initialiseColumns': {
      return action.payload
    }
    case 'initialiseColumnsIfNoneInState': {
      return Object.keys(state).length ? state: action.payload
    }
    case 'toggleColumnVisibility': {
      const field = action.payload
      const column = state[field]

      return {
        ...state,
        [field]: {
          ...column,
          disabled: !column.disabled
        }
      }
    }
    case 'renameColumn': {
      const { field, value } = action.payload
      const column = state[field]
      return {
        ...state,
        [field]: {
          ...column,
          renamed: value
        }
      }
    }
    case 'addColumn': {
      const index = Object.keys(state).length
      const field = String(index)
      const title = alphabetizeIndex(index)

      return {
        ...state,
        [field]: {
          field,
          index,
          title,
          additional: true,
          initialEditValue: '$cell',
        }
      }
    }
    case 'removeColumn': {
      const field: string = action.payload
      const { [field]: column, ...columns } = state
      return columns
    }
    default:
      return state
  }
}

export function getColumns(columnsState: Columns): Column[] {
  return Object.values(columnsState)
}
