import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from "@material-ui/core";
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'typeface-roboto';

function init() {
  if (window.location.host === 'boss-my-data.web.app') {
    window.location.host = 'bossmydata.com';
    return;
  }

  ReactDOM.render(
    <React.StrictMode>
      <CssBaseline>
        <App />
      </CssBaseline>
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

init();

