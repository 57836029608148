import { range, tail, isEmpty, reject, all, map, into, compose } from 'ramda'
import { Column } from './hooks/useColumnEditor/reducer'

export type Row = string[]
export type Data = Row[]

export function alphabetizeIndex(index: number): string {
  if (index < 26) {
    return String.fromCharCode(index + 65)
  }
  
  const x = Math.floor(index / 26)
  const remainder = (index % 26)

  return alphabetizeIndex(x) + alphabetizeIndex(remainder)
}

export function snakeCase(title: string) {
  return title.toLowerCase().replace(/\s/g, '_')
}

export function stringToRef(str: string): string | undefined {
  if (str !== '') {
    return '$' + snakeCase(str).replace(/[^a-zA-Z0-9_]/g, '').replace(/_*$/, '')
  }
}

const defaultColumnProps = {
  initialEditValue: '$cell',
}

export function getColumnsAndData(hasHeaders: boolean, data: Data) {
  let columns: Array<Column> = []

  if (hasHeaders) {
    data[0].forEach((title: string, index: number) => {
      const field = String(index)
      columns.push({ ...defaultColumnProps, field, index, title })
    })
  } else {
    const longestRow = data.reduce((a, c) => Math.max(a, c.length), 0)
    range(0, longestRow).forEach((index: number) => {
      const title = alphabetizeIndex(index)
      const field = String(index)
      columns.push({ ...defaultColumnProps, field, index, title })
    })
  }

  const rows = into(
    [],
    compose(
      // @ts-ignore
      reject((row: Row): boolean => all(isEmpty, row)),
      map((row: Row) => row.reduce((a, c, i) => ({ ...a, [columns[i].field]: c }), {}))
    ),
    hasHeaders ? tail(data) : data
  )

  return {
    data: rows,
    columns: columns.reduce((a, c) => ({ ...a, [c.field]: c }), {}),
  }
}
