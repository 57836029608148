import { firestore } from "../firebase";
import { splitEvery } from 'ramda';
import { query, where, getDocs, deleteDoc, doc, setDoc, collection, documentId } from "firebase/firestore";

export async function getUserMappings(user) {
  if (!user.mappings?.length) {
    return [];
  }

  const mappings = []
  const batches = splitEvery(10, user.mappings);

  for (const batch of batches) {
    const q = query(
      collection(firestore, "mappings"),
      where(documentId(), "in", batch)
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      if (doc.get('enabled')) {
        mappings.push({ id: doc.id, name: doc.get('name') })
      }
    });
  }

  return mappings
}

export function deleteMapping(mappingId) {
  return deleteDoc(doc(firestore, "mappings", mappingId))
}

export function renameMapping(mappingId, name) {
  return setDoc(doc(firestore, "mappings", mappingId), { name }, { merge: true });
}
