import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Dialog,
  DialogTitle,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import { ButtonLink } from "../../../components/ButtonLink";
import { firestore } from "../../../firebase";
import {
  doc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { useQuery, useMutation } from "react-query";
import { useStyles } from './styles';

export function AddMappingDialog({ user, onAssigned, onClose }) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { data: mappingData = [] } = useQuery("list-mappings/" + user.id, async () => {
    const mappings = [];
    const querySnapshot = await getDocs(collection(firestore, "mappings"));
    querySnapshot.forEach((doc) =>
      mappings.push({ id: doc.id, ...doc.data() })
    );
    return mappings;
  });

  const assignMappingsMutation = useMutation(async (mappings) => {
    try {
      await setDoc(
        doc(firestore, "users", user.id),
        { mappings: [...user.mappings.map(mapping => mapping.id), ...mappings] },
        { merge: true }
      );
      onAssigned();
      onClose();
      enqueueSnackbar("Assigned mapping to customer", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(
        "There was a problem trying to assing that mapping to a customer",
        { variant: "error" }
      );
    }
  });

  const userMappingIds = user.mappings?.map((mapping) => mapping.id) ?? [];
  const mappings = mappingData.filter(
    (mapping) => !userMappingIds.includes(mapping.id)
  );

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="add-mapping-title"
      onClose={onClose}
      open
    >
      <DialogTitle id="add-mapping-title">Add Mapping</DialogTitle>
      <Box pt={0} p={2}>
        {mappings?.length ? (
          <List style={{ width: 450 }}>
            {mappings.map((mapping) => {
              const value = mapping.id;
              const labelId = `checkbox-list-label-${value}`;
              return (
                <ListItem
                  key={value}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={mapping.name} />
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography>
            There are no mappings available to assign to the customer.
          </Typography>
        )}
        <div className={classes.actions + " " + classes.margin}>
          <Button onClick={onClose} variant="contained" color="default">
            Cancel
          </Button>
          {Boolean(mappings?.length) ? (
            <Button
              onClick={() => assignMappingsMutation.mutate(checked)}
              color="primary"
              variant="contained"
            >
              Add
            </Button>
          ) : (
            <ButtonLink
              type="button"
              to="/admin/mappings/add"
              color="primary"
              variant="contained"
            >
              Create a mapping
            </ButtonLink>
          )}
        </div>
      </Box>
    </Dialog>
  );
}


