import React, { useState } from "react";
import { ChevronLeft } from "@material-ui/icons";
import { useMutation } from "react-query";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Field } from "./TextField";
import Button from "./Button";
import { Formik, Form } from "formik";
import * as yup from "yup";

const email = yup.string().email().label("Email").default("").required();

const loginSchema = yup.object({
  email,
  password: yup.string().label("Password").default("").required(),
});

const resetSchema = yup.object({
  email,
});

export function LoginModal({ title = "Login", onSubmit }) {
  const [resetPassword, setResetPassword] = useState();

  const resetPasswordMutation = useMutation(async ({ email }) => {
    return sendPasswordResetEmail(auth, email, { url: window.location.origin });
  });

  const isReset =
    resetPasswordMutation.isSuccess || resetPasswordMutation.isError;

  const form = (() => {
    if (resetPassword) {
      return (
        <Formik
          initialValues={resetSchema.getDefaultFromShape()}
          validationSchema={resetSchema}
          validateOnMount={false}
          onSubmit={resetPasswordMutation.mutateAsync}
        >
          {() => (
            <Form>
              <DialogContent>
                <Field name="email" type="email" label="Email" />
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<ChevronLeft />}
                  disabled={resetPasswordMutation.isLoading}
                  onClick={() => setResetPassword(false)}
                  type="button"
                  variant="text"
                  color="primary"
                >
                  Back
                </Button>
                <Button
                  isLoading={resetPasswordMutation.isLoading}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Reset Password
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      );
    }

    return (
      <Formik
        initialValues={loginSchema.getDefaultFromShape()}
        validationSchema={loginSchema}
        validateOnMount={false}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <DialogContent>
              <Field name="email" type="email" label="Email" />
              <Field name="password" type="password" label="Password" />
            </DialogContent>
            <DialogActions>
              <Button
                type="button"
                onClick={() => setResetPassword(true)}
                variant="text"
                color="primary"
              >
                Reset Password
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Login
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    );
  })();

  return (
    <Dialog maxWidth="xs" fullWidth aria-labelledby="login-title" open>
      <DialogTitle id="login-title">
        {resetPassword ? "Reset Password" : title}
      </DialogTitle>
      {isReset ? (
        <DialogContent>
          <DialogContentText>
            Password reset has been requested
          </DialogContentText>
        </DialogContent>
      ) : (
        form
      )}
    </Dialog>
  );
}
