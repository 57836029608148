import React from "react";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between",
  },
  menu: {
    "& > *": {
      marginRight: theme.spacing(2),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <AppBar color="default" position="static">
      <Container maxWidth="lg">
        <Toolbar disableGutters className={classes.toolbar}>
          <Typography variant="h5" component="div">
            Boss My Data
          </Typography>
          <div className={classes.menu}>
            <Button
              onClick={() => {
                signOut(auth);
                history.push("/");
              }}
              color="inherit"
            >
              Sign out
            </Button>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
