import math from './math'

const allowedTypes = ['string', 'number', 'undefined', 'boolean', 'null']

export function evaluate(scope, formula) {
  math.import(scope, { override: true })
  const output = math.evaluate(formula)
  const isOutputValid = allowedTypes.includes(typeof output)
  return isOutputValid ? output : ''
}
