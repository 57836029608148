import React from "react";
import { useSnackbar } from "notistack";
import { makeStyles, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { ButtonLink } from "../../components/ButtonLink";
import { Field } from "../../components/TextField";
import Button from "../../components/Button";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useMutation } from 'react-query';
import { apiFetch } from "../../api";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 3),
    maxWidth: theme.breakpoints.values.sm,
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(2),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  margin: {
    margin: theme.spacing(2, 0, 1),
  },
}));

const validationSchema = yup.object({
  name: yup.string().label("Name").default("").required(),
  email: yup.string().email().label("Email").default("").required(),
});

export function AddUser({ back }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const addUserMutation = useMutation(async (values) => {
    try {
      await apiFetch("/users", {
        method: "POST",
        body: JSON.stringify(values),
      });

      enqueueSnackbar("User created", { variant: "success" });

      history.push(back);
    } catch (e) {
      console.log('e', e);
      enqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  })

  const formikProps = {
    initialValues: validationSchema.getDefaultFromShape(),
    validationSchema,
    validateOnMount: false,
    onSubmit: (values) => addUserMutation.mutate(values),
  };

  return (
    <>
      <PageTitle
        title="Add Customer"
        actions={
          <ButtonLink to={back} variant="contained" color="default">
            Back
          </ButtonLink>
        }
      />
      <Paper className={classes.paper} variant="outlined">
        <Formik {...formikProps}>
          {() => (
            <Form>
              <Field disabled={addUserMutation.isLoading} name="name" label="Name" />
              <Field disabled={addUserMutation.isLoading} name="email" type="email" label="Email" />
              <div className={classes.actions + " " + classes.margin}>
                <Button isLoading={addUserMutation.isLoading} type="submit" color="primary" variant="contained">
                  Create
                </Button>
                <ButtonLink disabled={addUserMutation.isLoading} to={back} variant="contained" color="default">
                  Cancel
                </ButtonLink>
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
