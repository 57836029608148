import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Typography } from '@material-ui/core'

export default function ColumnTransformError({ transformError }) {
  return (
    <Alert severity="error">
      <AlertTitle>Transform error</AlertTitle>
      { transformError.meta && 
        <Typography gutterBottom>
          Could not apply formula <strong>{transformError.meta.formula}</strong>{' '}
          to cell <strong>{transformError.meta.cell}</strong>{' '}
          { transformError.meta.column &&
            <>
              in column <strong>{transformError.meta.column.renamed ?? transformError.meta.column.title}</strong>
            </>
          }
        </Typography>
      }
      { transformError.meta?.message &&
        <Typography gutterBottom>
          {transformError.meta.message}
        </Typography>
      }
    </Alert> 
  )
}
