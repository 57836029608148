import React, { useState, useContext } from "react";
import { useSnackbar } from "notistack";
import {
  Link,
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import CodeMirror from "./CodeMirror";
import { FormulaReferencesContext } from "../formulas";
import functions from '../math/function-docs.json';

const useStyles = makeStyles((theme) => ({
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textfield: ({ size }) => ({
    "& input": {
      fontSize: size === "small" ? 14 : 16,
    },
  }),
  codemirror: {
    border: `1px solid ${theme.palette.text.secondary}`,
    height: "100%",
    "& .CodeMirror": {
      height: "100%",
      fontSize: 16,
    },
  },
  tableRow: {
    '&:hover': {
      background: theme.palette.action.hover,
    },
    cursor: 'pointer',
  }
}));

function functionSignaure(f) {
  return (
    `${f.name}(${f?.signature?.parameters?.map(p => p.isRest ? '...' : p.name).join(', ')})`
  )
}

export default function FormulaEditor({
  variant = undefined,
  placeholder = undefined,
  size = undefined,
  value,
  readOnly = false,
  onChange = () => undefined,
  ...props
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [stateValue, setStateValue] = useState(value);
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles({ size });
  const referencesInContext = useContext(FormulaReferencesContext)

  const references = [
    { title: "Cell", references: ["$cell"] },
    ...(props.references ?? referencesInContext)
  ];

  return (
    <>
      <TextField
        variant={variant}
        placeholder={placeholder}
        className={classes.textfield}
        disabled={readOnly}
        onClick={() => {
          if (!readOnly) {
            setDialogOpen(true);
          }
        }}
        value={value}
        readOnly={readOnly}
        size={size}
        fullWidth
      />
      <Dialog open={dialogOpen} fullScreen>
        <DialogContent>
          <Grid
            style={{ height: "100%" }}
            alignItems="stretch"
            spacing={4}
            container
          >
            <Grid
              xs={8}
              style={{
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              item
            >
              <Typography gutterBottom component="div" variant="h6">
                {!!readOnly ? "Edit Formula" : "View Formula"}
              </Typography>
              <CodeMirror
                className={classes.codemirror}
                options={{
                  mode: "text/javascript",
                  matchBrackets: true,
                  autoCloseBrackets: true,
                  extraKeys: {
                    "Ctrl-Space": "autocomplete",
                  },
                  readOnly,
                  lineNumbers: true,
                }}
                value={stateValue}
                onBeforeChange={(_, _1, value) => {
                  setStateValue(value);
                }}
              />
            </Grid>
            <Grid
              xs={4}
              style={{
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              item
            >
              <Box flex="0 1 50%" maxHeight="50%" mb={2} display="flex" flexDirection="column">
                <Typography gutterBottom variant="h6" component="div">
                  References
                </Typography>
                <TableContainer style={{ flex: 1, width: "100%", overflow: "auto" }}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Reference</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {references.map((r) => (
                        <TableRow className={classes.tableRow} key={r.title} onClick={() => {
                            navigator.clipboard.writeText(r.references[0])
                            enqueueSnackbar('Copied reference to clipboard');
                          }}>
                          <TableCell>{r.title}</TableCell>
                          <TableCell>
                            <Typography
                              component="span"
                              fontSize="inherit"
                              color="primary"
                            >
                              {r.references.join(" ")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Typography variant="h6" component="div">
                Functions
              </Typography>
              <Box flex="0 1 50%" overflow="auto">
                <List disablePadding>
                  {functions.map((f, i) => (
                    <ListItem key={i} button onClick={() => {
                        navigator.clipboard.writeText(functionSignaure(f))
                        enqueueSnackbar('Copied function to clipboard');

                      }} dense>
                      <Tooltip title={f.comment.shortText} placement="left">
                        <ListItemText
                          primary={functionSignaure(f)}
                          secondary={
                            <>
                              {f.comment.tags?.map(({ tag: type, text }, i) => {
                                if (type === "example") {
                                  return (
                                    <React.Fragment key={i}>
                                      Example: <code>{text}</code>
                                      <br />
                                    </React.Fragment>
                                  );
                                }
                                if (type === "see") {
                                  return (
                                    <>
                                      <Link href={text} target="_blank">
                                        See
                                      </Link>
                                      <br />
                                    </>
                                  );
                                }
                              })}
                            </>
                          }
                        />
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!readOnly && (
            <Button
              onClick={() => {
                onChange(stateValue);
                setDialogOpen(false);
              }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              if (!readOnly) {
                setStateValue(value);
              }
              setDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
