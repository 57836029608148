import { createContext } from 'react'
import * as math from './math'
import { Column } from './hooks/useColumnEditor/reducer'
import { stringToRef, alphabetizeIndex } from './util'

type Row = Record<string, string>

interface CellTransformExceptionMeta {
  message?: string
  column: Column
  formula: string
  cell: string
}

type Reference = {
  title: string
  references: string[]
}

export const FormulaReferencesContext = createContext([] as Reference[]);

export function getColumnReferences(column: Column) {
  const reference = '$' + alphabetizeIndex(Number(column.field))
  const refs = [reference, reference.toLowerCase()]
  const humanReadableRef = stringToRef(column.title)

  if (humanReadableRef && !refs.includes(humanReadableRef))
    refs.push(humanReadableRef)

  return refs
}

/**
 * @hidden
 */
export class InputError extends Error {}

export interface CellTransformException {
  name: string
  meta: CellTransformExceptionMeta
}

export class CellTransformException extends Error implements CellTransformException {
  constructor(error: Error, meta: CellTransformExceptionMeta) {
    super(error.message)
    this.name = "ColumnFormulaEvalException"
    this.meta = meta

    if (error instanceof InputError) {
      this.meta.message = error.message
    }
  } 
}

export function referenceRow(row: Row, columns: Record<string, Column>, columnKeys: string[]): Record<string, string> {
  return columnKeys.reduce((a: Record<string, string>, key) => {
    const reference = '$' + alphabetizeIndex(Number(key))
    const column = columns[key]
    const cell = row[key] ?? ''

    const humanReadableRef = stringToRef(column.title)

    const references = {
      ...a,
      [reference]: cell,
      [reference.toLowerCase()]: cell,
    }

    if (humanReadableRef) {
      references[humanReadableRef] = cell
    }

    return references
  }, {})
}

export function applyFormulas(
  rowFormulae: any,
  columns: Record<string, Column>,
  rows: Row[]
) {
  const columnKeys = Object.keys(columns)
  const visibleColumns = Object.values(columns).filter(c => !c.disabled)

  return rows.flatMap((row: Row) => 
    rowFormulae.map((formulaRow: any) =>
      visibleColumns.reduce((evaluatedRow: Record<string, string>, column: Column) => {
        const cell = row[column.field] ?? ''
        const formula = formulaRow[column.field]

        if (formula && formula !== '$cell') {
          const scope: Record<string, string> = referenceRow(row, columns, columnKeys)
          scope.$cell = cell

          try {
            const evaluated = math.evaluate(scope, formula)
            evaluatedRow[column.field] = evaluated
          } catch (error) {
            console.log(scope)
            console.error(error)
            throw new CellTransformException(
              error,
              { column, formula, cell }
            )
          }
        } else {
          evaluatedRow[column.field] = cell
        }

        return evaluatedRow
      }, {})
    )
  )
}

export function applyFormulaToCell(
  field: string,
  formulas: any,
  row: Row,
  columns: Record<string, Column>,
  columnKeys: string[],
) {
  const cell = row[field] ?? ''
  const formula = formulas[field]

  if (formula && formula !== '$cell') {
    const scope = referenceRow(row, columns, columnKeys)
    scope.$cell = cell

    try {
      const evaluated = math.evaluate(scope, formula)
      return evaluated
    } catch (error) {
      throw new CellTransformException(
        error,
        {
          column: columns[field],
          formula: formulas[field],
          cell
        }
      )
    }
  }

  return cell
}
