export const styles = (theme) => ({
  toolbar: {
    justifyContent: "space-between",
  },
  menu: {
    "& > *": {
      marginRight: theme.spacing(2),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
  welcome: {
    padding: theme.spacing(5, 3),
    marginBottom: theme.spacing(4),
    "& > *": {
      maxWidth: 850,
    },
    "& h1": {
      marginBottom: theme.spacing(4),
    },
    "& p:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  transformers: {
    "& h2": {
      marginBottom: theme.spacing(3),
    },
  },
  transformer: {
    padding: theme.spacing(3),
  },
  transformerName: {
    textTransform: "capitalize",
    marginBottom: theme.spacing(3),
  },
})

export const loadingStyles = (theme) => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
  },
})
