import React, { useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { doc, getDoc, addDoc, collection, setDoc } from "firebase/firestore";
import { Button, Box } from '@material-ui/core';
import { useRouteMatch, useHistory } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { ButtonLink } from "../../components/ButtonLink";
import MappingTags from "../../components/MappingTags";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import RenameMappingDialog from './RenameMappingDialog';
import { firestore } from "../../firebase";
import { useQuery, useMutation } from "react-query";
import Editor from './Editor/Editor';
import { deleteMapping } from '../../domain/mappings'
import { pipe, assoc, dissoc, evolve } from 'ramda';

function pruneDataForDb(values) {
  return evolve({ rowFormulae: (rows) => rows.map(dissoc('tableData')) }, values)
}

export function ManageMapping({ back }) {
  const history = useHistory();
  const { params } = useRouteMatch();
  const editorRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null)

  const { data: mapping, isLoading, refetch: refetchMapping } = useQuery(["mapping", params.mappingId], async () => {
    const docSnapshot = await getDoc(
      doc(firestore, "mappings", params.mappingId)
    );

    if (!docSnapshot.exists()) {
      history.push('/admin/mappings')
    }

    return { id: docSnapshot.id, ...docSnapshot.data() };
  });

  const saveMappingMutation = useMutation(async (values) => {
    try {
      setDoc(doc(firestore, "mappings", mapping.id), pruneDataForDb(values), { merge: true });
      enqueueSnackbar('Updated mapping configuration', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('There was a problem updating the mapping', { variant: 'error' });
    }
  })

  const cloneMappingMutation = useMutation(async (values) => {
    const cloned = pipe(
      dissoc('id'),
      assoc('name', values.name + " (clone)"),
      pruneDataForDb,
    )(values)

    try {
      const doc = await addDoc(collection(firestore, "mappings"), cloned);
      enqueueSnackbar('Cloned mapping', { variant: 'success' });
      history.push(back + "/manage/" + doc.id)
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('There was a problem cloning the mapping', { variant: 'error' });
    }
  })

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [renameDialogOpen, setRenameDialogOpen] = useState(false)

  const deleteMappingMutation = useMutation(async () => {
    try {
      await deleteMapping(params.mappingId);
      enqueueSnackbar('Deleted mapping', { variant: 'success' });
      history.push('/admin/mappings')
    } catch (e) {
      enqueueSnackbar("Couldn't delete mapping", { variant: "error" });
    }
  })

  if (isLoading) {
    return null;
  }

  return (
    <>
      <PageTitle
        title={"Manage Mapping" + (mapping?.name ? " - " + mapping.name : null)}
        actions={
          <>
            <Button
              disabled={Boolean(error)}
              onClick={() => {
                saveMappingMutation.mutate(editorRef.current);
              }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              disabled={Boolean(error)}
              onClick={() => {
                cloneMappingMutation.mutate(mapping);
              }}
              variant="outlined"
              color="primary"
            >
              Clone
            </Button>
            <Button
              variant="outlined"
              onClick={async () => {
                setRenameDialogOpen(true);
              }}
            >
              Rename
            </Button>
            <Button
              variant="outlined"
              onClick={async () => {
                setDeleteDialogOpen(true);
              }}
            >
              <Box color="error.main">Delete</Box>
            </Button>
            <ButtonLink to={back} variant="contained" color="default">
              Back
            </ButtonLink>
          </>
        }
      />
      <Box mb={2}>
        <MappingTags mappingId={params.mappingId} />
      </Box>
      <RenameMappingDialog
        open={renameDialogOpen}
        mappingId={params.mappingId}
        onSuccess={async () => {
          setRenameDialogOpen(false);
          await refetchMapping();
          enqueueSnackbar("Renamed", { variant: "success" });
        }}
        onClose={() => setRenameDialogOpen(false)}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete Mapping"
        action="Delete"
        message={`Are you sure you should delete "${mapping.name}"? This action can not be reversed.`}
        mutation={deleteMappingMutation}
        onClose={() => setDeleteDialogOpen(false)}
      />
      <Editor ref={editorRef} mapping={mapping} setError={setError} />
    </>
  );
}
