import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyArn9hot0zWIBIaTGpVU3QxHU1c2vEncWI",
  authDomain: "boss-my-data.firebaseapp.com",
  projectId: "boss-my-data",
  storageBucket: "boss-my-data.appspot.com",
  messagingSenderId: "98800243145",
  appId: "1:98800243145:web:5b0a600d2576480ab75818"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);

if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "http://localhost:5001");
  connectFirestoreEmulator(firestore, 'localhost', 8080);
}

export {
  app,
  auth,
  functions,
  firestore,
}
