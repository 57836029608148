import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import { useRecoilState } from "recoil";
import Papa from "papaparse";
import { Box, FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import ColumnEditor from "./components/ColumnEditor";
import InputFileHeader from "./components/InputFileHeader";
import AggregateEditor from "./components/AggregateEditor";
import TableTransformEditor from "./components/TableTransformEditor";
import useColumnEditor from "./hooks/useColumnEditor";
import useTableTransformEditor from "./hooks/useTableTransformEditor";
import { getColumnsAndData } from "./util";
import { aggregateState } from "./aggregate";
import OutputPreviewTable from "./components/OutputPreviewTable";
import { FormulaReferencesContext, getColumnReferences } from "./formulas";

const App = forwardRef((props, ref) => {
  const [file, setFile] = useState();
  const [includeHeadersInOutput, setIncludeHeadersInOutput] = useState(
    props.mapping.includeHeadersInOutput ?? false
  );
  const [enabled, setEnabled] = useState(
    props.mapping.enabled ?? false
  );
  const [inputData, setInputData] = useState(null);

  const [hasHeaders, setHasHeaders] = useState(true);

  const [aggregationMapping, setAggregationMapping] =
    useRecoilState(aggregateState);
  const columnEditor = useColumnEditor(props.mapping.columnsState);

  const {
    columns,
    columnsState,
    initialiseColumns,
    toggleColumnVisibility,
    renameColumn,
    addColumn,
    removeColumn,
  } = columnEditor;

  const columnOutput = columns
    .filter((c) => !c.disabled)
    .map((c) => ({
      ...c,
      title: c.renamed || c.title,
    }));

  const rowTransformEditor = useTableTransformEditor(props.mapping.rowFormulae);
  const { rowFormulae } = rowTransformEditor;

  useImperativeHandle(ref, () => ({
    includeHeadersInOutput,
    columnsState,
    rowFormulae,
    aggregationMapping,
    enabled
  }));

  useEffect(() => {
    if (props.mapping.aggregationMapping) {
      setAggregationMapping(props.mapping.aggregationMapping);
    }
  }, [props.mapping.aggregationMapping, setAggregationMapping]);

  return (
    <FormulaReferencesContext.Provider
      value={Object.values(columnsState).map((c) => ({
        title: c.renamed ?? c.title,
        references: getColumnReferences(c),
      }))}
    >
      <Box>
        <Box mb={2}>
          <InputFileHeader
            hasHeaders={hasHeaders}
            setHasHeaders={(nextHasHeadersState) => {
              setHasHeaders(nextHasHeadersState);
              if (file) {
                const { data } = getColumnsAndData(
                  nextHasHeadersState,
                  file.results.data
                );
                setInputData(data);
              }
            }}
            file={file}
            setFile={(newFile) => {
              if (newFile) {
                Papa.parse(newFile, {
                  complete(results) {
                    setFile({ name: newFile.name, results });
                    const { columns, data } = getColumnsAndData(
                      hasHeaders,
                      results.data
                    );

                    if (!props.mapping.columnsState) {
                      initialiseColumns(columns);
                    }

                    setInputData(data);
                  },
                });
              }
            }}
          />
        </Box>
        <>
          {inputData && (
            <>
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(enabled)}
                      onChange={() => setEnabled(!enabled)}
                      color="primary"
                    />
                  }
                  label={<Typography color="textSecondary">Enabled</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(includeHeadersInOutput)}
                      onChange={() =>
                        setIncludeHeadersInOutput(!includeHeadersInOutput)
                      }
                      color="primary"
                    />
                  }
                  label={
                    <Typography color="textSecondary">
                      Include headers in output
                    </Typography>
                  }
                />
              </Box>
              <Box mb={2}>
                <ColumnEditor
                  columns={columns}
                  toggleColumnVisibility={toggleColumnVisibility}
                  updateColumnName={renameColumn}
                  addColumn={addColumn}
                  removeColumn={removeColumn}
                />
              </Box>
              <Box mb={2}>
                <TableTransformEditor
                  columns={columnOutput}
                  {...rowTransformEditor}
                />
              </Box>
              <Box mb={2}>
                <AggregateEditor columns={columnOutput} />
              </Box>
            </>
          )}
          {inputData && (
            <OutputPreviewTable
              title="Output Data"
              columns={columnOutput}
              columnsState={columnsState}
              rowFormulae={rowFormulae}
              data={inputData}
              setError={props.setError}
            />
          )}
        </>
      </Box>
    </FormulaReferencesContext.Provider>
  );
});

export default App;
