import React from 'react'
import { useRecoilState } from 'recoil'
import { Paper, Box, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core'
import { Column } from '../../hooks/useColumnEditor/reducer'
import { aggregateState, updateExpression, groupColumn } from '../../aggregate'
import FormulaEditor from '../FormulaEditor'

enum ColumnState {
  group = 'group',
  aggregate = 'aggregate',
}

interface AggregatedColumn {
  group: ColumnState
  expression?: string
}

type AggregationState = Record<string | number, AggregatedColumn>

interface AggregateEditorColumnProps {
  title: string
  value: string
  expression?: string
  checked: boolean
  onChange(): void
  onExpressionChange(expression: string): void
}

function AggregateEditorColumn({
  title,
  expression = '',
  checked,
  onChange,
  onExpressionChange
}: AggregateEditorColumnProps) {
  const expressionLabel = checked ? 'Grouping predicate' : 'Aggregating expression' 

  return (
    <Paper variant="outlined" square>
      <Box p={1} pl={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={3} item>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              label='Group'
              control={
                <Switch
                  checked={checked}
                  onChange={onChange}
                  color="primary"
                />
              }
            />
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <FormulaEditor
              variant="outlined"
              placeholder={expressionLabel}
              value={expression}
              references={[{ title: 'Accumulated value', references: ['$acc'] }]}
              // @ts-expect-error bad types
              onChange={onExpressionChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

interface AggregateEditorProps {
  aggregateState: AggregationState
  dispatchAggregateAction: ({ type, payload }: { type: string, payload?: any }) => void
  columns: Column[]
}

export default function AggregateEditor({ columns }: AggregateEditorProps) {
  const [state, setState] = useRecoilState<AggregationState>(aggregateState)

  return (
    <Box>
      <Paper variant="outlined" square>
        <Box py={2.5} pl={2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            Aggregates
          </Typography>
        </Box>
      </Paper>
      {columns.map((c: Column) => (
        <AggregateEditorColumn
          key={c.field}
          value={c.field}
          expression={state[c.field]?.expression}
          title={c.title}
          checked={state[c.field]?.group === ColumnState.group}
          onExpressionChange={expression => setState(updateExpression(state, {
            field: c.field,
            expression
          }))}
          onChange={() => {
            const group = state[c.field]?.group === ColumnState.group
              ? ColumnState.aggregate
              : ColumnState.group

            setState(groupColumn(state, { group, field: c.field }))
          }}
        />
      ))}
    </Box>
  )
}
