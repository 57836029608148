import React from 'react';
import MaterialTable from '../MaterialTable';
import FormulaEditor from '../FormulaEditor';

interface Column {
  field: string
  title: string
  additional?: boolean
  enabled?: boolean
  tableData: { id: string }
}

type RowFormulae = string[]

interface Props {
  columns: Array<Column>
  rowFormulae: RowFormulae[]
  updateRow: ({ updatedRow, index }: { updatedRow: RowFormulae, index: number }) => void,
  addRow: (payload: RowFormulae) => void,
  deleteRow: (index: number) => void,
}

export default function TableTransformEditor({
  columns,
  rowFormulae,
  updateRow,
  addRow,
  deleteRow,
}: Props) {
  return (
    <MaterialTable
      title="Transform rows"
      localization={{
        body: {
          emptyDataSourceMessage: 'No transforms applied',
        }
      }}
      data={rowFormulae}
      columns={columns.map(c => ({
        ...c,
        render: (rowData: RowFormulae) => (
          <FormulaEditor size="small" value={rowData[Number(c.field)]} readOnly />
        ),
        editComponent: (props: any) => (
          <FormulaEditor size="small" value={props.value} onChange={props.onChange} />
        )
      }))}
      options={{ draggable: false, sorting: false, search: false, paging: false, actionsColumnIndex: -1 }}
      editable={{
        onRowAdd: async (payload: any) => { addRow(payload) },
        onRowUpdate: async (updatedRow: RowFormulae, oldData: any) => {
          const index = oldData.tableData.id
          updateRow({ updatedRow, index })
        },
        onRowDelete: async (oldData: any) => {
          const index = oldData.tableData.id
          deleteRow(index)
        },
      }}
    />
  )
}
