import React from "react";
import { CircularProgress, Button } from "@material-ui/core";

export default function AppButton({
  isLoading,
  loadingIconColor = "inherit",
  ...props
}) {
  return (
    <Button
      {...props}
      disabled={props.disabled || isLoading}
      startIcon={
        isLoading ? (
          <CircularProgress size={16} color={loadingIconColor} />
        ) : (
          props.startIcon
        )
      }
    />
  );
}
