import {
  format as baseFormat,
  parse as baseParse
} from 'date-fns'

const defaultFormat = 'dd/MM/yyyy'

export function parse(dateString, f = defaultFormat) {
  return baseParse(dateString, f, new Date())
}

export function format(date, f = defaultFormat) {
  return baseFormat(date, f)
}
