import React from 'react'
import { Typography, Grid, Paper, FormControlLabel, Checkbox, Box } from '@material-ui/core'
import { ArrowRightAlt } from '@material-ui/icons'
import FileUpload from '../../../../components/FileUpload'

interface Props {
  hasHeaders: boolean,
  setHasHeaders: (hasHeaders: boolean) => void,
  file: File,
  setFile: (file: File) => void
}

export default function InputFileHeader({ hasHeaders, setHasHeaders, file, setFile }: Props) {
  return (
    <Paper variant="outlined" square>
      <Box p={2}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            { file ?
              <Typography variant="button" component="h2">
                File: {file.name}
              </Typography>
              :
              <>
                <Typography variant="button" component="h2">
                  Select an input file to work with
                </Typography>
                <ArrowRightAlt />
              </>
            }
          </Grid>
          {file ? null : (
            <Grid item xs={6} container justifyContent="flex-end">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={hasHeaders}
                        onChange={e => setHasHeaders(e.target.checked)}
                      />
                    }
                    label={<Typography color="textSecondary">Take first row as headers</Typography>}
                  />
                </Grid>
              <Grid item>
                <FileUpload
                  id="csv-input-file"
                  accept=".csv"
                  label="Select CSV"
                  onChange={setFile}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  )
}
