import { useReducer } from 'react'
import { useActions } from '../useAction'
import reducer from './reducer'

const actionTypes = [
  'initialiseRowFormulae',
  'addRow',
  'updateRow',
  'deleteRow'
]

export default function useTableTransformEditor(initialState = [])  {
  const [rowFormulae, dispatch] = useReducer(reducer, initialState) 
  const actions = useActions(dispatch, actionTypes)

  return {
    rowFormulae,
    ...actions
  }
}

