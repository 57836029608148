import React from "react";
import * as yup from "yup";
import { useHistory } from 'react-router-dom';
import { Button, Paper } from "@material-ui/core";
import { PageTitle } from "../../components/PageTitle";
import { Field } from "../../components/TextField";
import { Formik, Form } from "formik";
import { ButtonLink } from "../../components/ButtonLink";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { firestore } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { useStyles } from './styles';

const validationSchema = yup.object({
  name: yup.string().label("Name").default("").required(),
});

export default function AddMapping({ back }) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const createMappingMutation = useMutation(async (values) => {
    try {
      const doc = await addDoc(collection(firestore, "mappings"), values);
      enqueueSnackbar("Mapping created", { variant: "success" });
      history.push(back + "/manage/" + doc.id)
    } catch (e) {
      enqueueSnackbar("There was a problem creating the mapping.", {
        variant: "error",
      });
    }
  });

  const formikProps = {
    initialValues: validationSchema.getDefaultFromShape(),
    validationSchema,
    validateOnMount: false,
    onSubmit: (values) => createMappingMutation.mutate(values),
  };

  return (
    <>
      <PageTitle
        title="Add Mapping"
        actions={
          <ButtonLink to={back} variant="contained" color="default">
            Back
          </ButtonLink>
        }
      />
      <Paper className={classes.paper} variant="outlined">
        <Formik {...formikProps}>
          {() => (
            <Form>
              <Field name="name" label="Name" />
              <div className={classes.actions + " " + classes.margin}>
                <Button type="submit" color="primary" variant="contained">
                  Create
                </Button>
                <ButtonLink
                  to={back}
                  type="button"
                  variant="contained"
                  color="default"
                >
                  Cancel
                </ButtonLink>
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
