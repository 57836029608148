import React from 'react';
import {
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    flexWrap: 'wrap',
    gridGap: theme.spacing(2),
  },
  title :{
    flexGrow: 1,
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    "& > *": {
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: 0,
      },
    },
  }
}));

export function PageTitle({ title, actions, headingLevel = 'h1' }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4" component={headingLevel}>
        {title}
      </Typography>
      <div className={classes.actions}>
        {actions}
      </div>
    </div>
  )
}
