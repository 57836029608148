import { floor, round } from 'mathjs'
import { InputError } from '../formulas'

import {
  getDay,
  startOfToday,
  endOfMonth,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns'

import { parse, format } from './util'

type Input = string | number

/**
 * Check if a == b
 * @example equals(1, 1) == true
 */
export function equals(a: Input, b: Input) {
  return a == b // eslint-disable-line
}

/**
 * Check if a == b
 * @example isEqual(1, 1) == true
 */
export function isEqual(a: Input, b: Input) {
  return a == b // eslint-disable-line
}

/**
 * Negate the input
 * @example not(equal(1, 2)) == true
 */
export function not(x: any) {
  return !x
}

/**
 * Logical or
 * @example or(false, false, true) == true
 */
export function or(...args: any[]) {
  if (args.length < 2) {
    throw new InputError('or() requires at least two arguments')
  }
  
  return args.reduce((acc, arg) => acc || arg)
}

/**
 * Logical and
 * @example and(true, true, true) == true
 */
export function and(...args: any[]) {
  if (args.length < 2) {
    throw new InputError('and() requires at least two arguments')
  }

  return args.reduce((acc, arg) => acc && arg)
}

/**
 * Logical test
 * @example ifElse(equals(1, 1), 1, 0) == 1
 */
export function ifElse(condition: Input, then: Input, orElse: Input = '') {
  return condition ? then : orElse
}

/**
 * Make a date string
 * @example date(2021, 1, 22) == 22/01/2021
 */
export function date(year: Input, month: Input, day: Input) {
  return format(new Date(Number(year), Number(month) - 1, Number(day)))
}

/**
 * Todays date
 * @example now() == 22/01/2021
 */
export function now() {
  return format(new Date())
}

/**
 * Format dates
 * @see https://date-fns.org/v2.15.0/docs/format
 */
export function formatDate(dateString: string, outputFormat?: string, parsingFormat?: string) {
  const parsedDate = parse(dateString, parsingFormat)
  
  try {
    const formattedDate = format(parsedDate, outputFormat)
    return formattedDate
  } catch (e) {
    return dateString
  }
}

/**
 * Join multiple pieces of text
 *
 * @example concatenate('Hello', ' ', $cell) == 'Hello world'
*/
export function concatenate(...args: Array<Input>) {
  return args.join('')
}

/**
 * Join multiple pieces of text
 *
 * @example concat('Hello', ' ', $cell) == 'Hello world'
*/
export function concat(...args: Array<Input>) {
  return concatenate(...args)
}

/**
 * Get the left part of the text ending at character numbered 'end'
 *
 * @example left(2, 'hello') == 'he' 
*/
export function left(end: number, string: string) {
  return string.slice(0, end)
}

/**
 * Get the right part of the text ending at character numbered 'end'
 *
 * @example right(2, 'hello') == 'lo' 
*/
export function right(start: number, string: string) {
  return string.slice(string.length - start, string.length)
}

/**
 * Get the middle part of the string
 *
 * @example mid(0, 2 'hello') == 'hel'
 * @example mid(1, 2,'hello') == 'el' 
*/
export function mid(start: number, end: number, string: string) {
  return string.slice(start, end + 1)
}

/**
 * Check if the number is odd
 *
 * @example odd(1) == true
*/
export function odd(number: Input) {
  return Number(number) % 2 !== 0
}

/**
 * Check if the number is even
 * @example even(1) == false
 */
export function even(number: Input) {
  return !odd(number)
}

/**
 * Get the length of the text
 * @example len('hello') == 5
 */
export function len(string: Input) {
  return String(string).length
}

/** 
 * Get the index of the text in some text
 * @example find('ll', 'hello') == 2
 * @example find('he', 'hello') == 0
 */
export function find(text: string, string: string): number {
  return string.indexOf(text)
}

/** 
 * Replace some text with another text.
 * @example substitute('hi', 'bye', 'hi friend') == 'bye friend'
 */
export function substitute(find: string, replace: string, string: string) {
  return string.replace(find, replace)
}

/** 
 * Change some value to text
 * @example text(1) == '1'
 */
export function text(number: number) {
  return String(number)
}

/** 
 * Change text to upper case
 * @example upper('hello') == HELLO
 */
export function upper(string: string) {
  return string.toUpperCase()
}

/** 
 * Change text to lower case
 * @example lower('HELLO') == hello
 */
export function lower(string: string) {
  return string.toLowerCase()
}

/**
 *  Convert some value to a number
 * @example value(2) == '2'
 */
export function value(string: string) {
  return Number(string)
}

/**
 * Get the index of a regular expression
 * @example search('\(\w+\)', '1000 (GBP)') == 5
 */
export function search(regexp: string, string: string): number {
  const match = new RegExp(regexp).exec(string)
  return match?.index ?? -1
}

/**
 * Get the index of a regular expression
 * @example proper('hello world') == 'Hello World'
 */
export function proper(string: string): string {
  return string.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
}

/**
 * Get the day of the week
 * @example weekday(today()) == 1
 */
export function weekday(dateString: string): number {
  const date = parse(dateString)
  return getDay(date)
}

/**
 * Get todays date
 * @example today() == 22/01/2022
 */
export function today() {
  return startOfToday()
}

/**
 * Get the week number of the year for a given date
 * @example weeknum(today())
 */
export function weeknum(dateString: string) {
  return Number(format(parse(dateString), 'w'))
}

/**
 * Get the year for a date
 * @example weeknum(today()) == '2022'
 */
export function year(dateString: string) {
  return format(parse(dateString), 'yyyy')
}

/**
 * Detect a positive or negative number
 * @example sign(-4) == -1
 * @example sign(0) == 0
 * @example sign(44) == 1
 */
export function sign(number: Input) {
  return (number > 0) ? 1 : (number < 0) ? -1 : 0
}

/**
 * Get the number of minutes past the hour for a datetime
 */
export function minute(dateString: string, f?: string | undefined) {
  return format(parse(dateString, f), 'm')
}

/**
 * Get the number of seconds past the minute for a datetime
 */
export function second(dateString: string, f?: string | undefined) {
  return format(parse(dateString, f), 's')
}

/**
 * Get the number of hours into the day for a datetime
 */
export function hour(dateString: string, f?: string | undefined) {
  return format(parse(dateString, f), 'H')
}

/**
 * Get the time (HH:mm:ss) for a given datetime
 */
export function time(dateString: string, f?: string | undefined) {
  return format(parse(dateString, f), 'HH:mm:ss')
}

/**
 * Get the month of the datetime
 */
export function month(dateString: string, f?: string | undefined) {
  return format(parse(dateString, f), 'M')
}

/**
 * Get the date at the end of the month for a given datetime
 */
export function eomonth(dateString: string, f?: string | undefined): string {
  const input = dateString ? parse(dateString, f) : new Date()
  return format(endOfMonth(input))
}

/**
 *  Get the number of days between 2 datetimes
 */
export function days(earlier: string, later: string): number {
  return differenceInDays(parse(earlier), parse(later))
}

/**
 *  Get the number of months between 2 datetimes
 */
export function months(earlier: string, later: string) {
  return differenceInMonths(parse(earlier), parse(later))
}

/**
 *  Get the number of years between 2 datetimes
 */
export function years(earlier: string, later: string) {
  return differenceInYears(parse(earlier), parse(later))
}

/**
 *  Round a number down to the nearest integer
 */
export function rounddown(number: number) {
  return floor(number)
}

/**
 *  Round a number up to the nearest integer
 */
export function roundup(number: number) {
  return round(number)
}

/**
 *  Round a number up to the number of decimal places given.
 */
export function fixed(number: number, n = 0) {
  return Number(number).toFixed(n)
}
