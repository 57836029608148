import { auth } from "./firebase";

const baseUrl = process.env.REACT_APP_API_HOST ?? "http://localhost:5001/boss-my-data/europe-west2/app";
export const apiUrl = url => baseUrl + url;

export const withAuthToken = async (config = { headers: {} }) => {
  if (!config.headers) {
    config.headers = {}
  }

  const idToken = await auth.currentUser?.getIdToken();

  if (idToken) {
    config.headers.authorization = `Bearer ${idToken}`;
  }

  return config;
}

export async function apiFetch(url, init) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    ...init,
  }

  await withAuthToken(config)

  const res = await fetch(
    apiUrl(url),
    config
  );

  if (!res.ok) {
    const { errors } = await res.json();
    console.log('errors', errors)
    throw new Error(errors);
  }

  return res.json();
}
