import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/javascript-hint'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/edit/closebrackets'
import CodeMirror from 'codemirror'
import { Controlled as ReactCodeMirror } from 'react-codemirror2'
import * as functions from '../math/functions'

const list  = [...Object.keys(functions), '$cell']

CodeMirror.hint.javascript = function (editor) {
  const cursor = editor.getCursor();
  var currentLine = editor.getLine(cursor.line);
  var start = cursor.ch;
  var end = start;
  while (end < currentLine.length && /[\w$]+/.test(currentLine.charAt(end))) ++end;
  while (start && /[\w$]+/.test(currentLine.charAt(start - 1))) --start;
  var curWord = start !== end && currentLine.slice(start, end);
  var regex = new RegExp('^' + curWord, 'i');
  var result = {
    list: (!curWord ? list : list.filter(function (item) {
      return item.match(regex);
    })).sort(),
    from: CodeMirror.Pos(cursor.line, start),
    to: CodeMirror.Pos(cursor.line, end)
  };

  return result;
}

export function preventMultiline(changeObj, value) {
  const typedNewLine = changeObj.origin === '+input'
    && typeof changeObj.text === 'object'
    && changeObj.text.join('') === ''

  if (typedNewLine) {
    changeObj.cancel()
    return false
  }

  const pastedNewLine = changeObj.origin === 'paste'
    && typeof changeObj.text == 'object'
    && changeObj.text.length > 1

  if (pastedNewLine) {
    const newText = changeObj.text.join(' ');
    changeObj.update(null, null, [newText]);
    return newText
  }

  return value
}

export default ReactCodeMirror
