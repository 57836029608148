import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  link: {
    color: "unset",
    textDecoration: "none",
  },
});

export function ButtonLink({ to, ...props }) {
  const classes = useStyles();
  return (
    <Link className={classes.link} to={to}>
      <Button tabIndex={-1} {...props} />
    </Link>
  );
}
