const initialState: Array<Array<string>> = []

export default function derivedRowReducer(state = initialState, action: any) {
  switch (action.type) {
    case 'initialiseRowFormulae': {
      return action.payload
    }
    case 'addRow': {
      const newRow = action.payload
      return [...state, newRow]
    }
    case 'updateRow': {
      const { updatedRow, index } = action.payload
      const stateUpdate = [...state]
      stateUpdate[index] = updatedRow
      return stateUpdate
    }
    case 'deleteRow': {
      const index = action.payload
      const stateUpdate = [...state]
      stateUpdate.splice(index, 1)
      return stateUpdate
    }
    default:
      return state 
  }
}
