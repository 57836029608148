import React, { useState, useMemo } from "react";
import { Box, Chip } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useRouteMatch } from "react-router-dom";
import { Table } from "../../components/Table";
import { ButtonLink } from "../../components/ButtonLink";
import { PageTitle } from "../../components/PageTitle";
import { firestore } from "../../firebase";
import { getDocs, collection } from "firebase/firestore";
import { useQuery } from 'react-query';

export default function ListMappings() {
  const { url } = useRouteMatch();

  const { data: users } = useQuery('list-users', async () => {
    const users = [];
    const querySnapshot = await getDocs(collection(firestore, "users"));
    querySnapshot.forEach((doc) => users.push({ id: doc.id, ...doc.data() }));
    return users
  })

  const { data: mappings } = useQuery('list-mappings', async () => {
    const mappings = [];
    const querySnapshot = await getDocs(collection(firestore, "mappings"));
    querySnapshot.forEach((doc) => mappings.push({ id: doc.id, ...doc.data() }));
    return mappings
  })

  return (
    <>
      <PageTitle
        title="Manage Mappings"
        actions={
          <ButtonLink to={url + "/add"} variant="contained" color="default">
            Add Mapping
          </ButtonLink>
        }
      />
      <Table
        search={{
          placeholder: "Filter by name or tag",
          match: (m, search, textMatch) => {
            return (
              textMatch(m.name, search) ||
              m.tags?.some((t) => textMatch(t, search))
            );
          }
        }}
        rowId="id"
        columns={[
          { field: "name", title: "Name" },
          {
            field: "assigned",
            title: "Assigned",
            align: "center",
            render(_, row) {
              const isAssigned = users.filter((u) =>
                u.mappings?.includes(row.id)
              );
              return (
                Boolean(isAssigned.length) && (
                  <Box color="success.main">
                    <Check color="inherit" />
                  </Box>
                )
              );
            },
          },
          {
            field: "tags",
            title: "Tags",
            align: "center",
            render(_, row) {
              return (
                <Box maxWidth="100%" display="flex" gridGap={5} justifyContent="center" flexWrap="wrap">
                  {row.tags?.map((x) => (
                    <Chip key={x} label={x} />
                  ))}
                </Box>
              );
            },
          },
          {
            field: "manage",
            title: "",
            align: "right",
            render(_, row) {
              return (
                <ButtonLink to={url + "/manage/" + row.id}>Manage</ButtonLink>
              );
            },
          },
        ]}
        rows={mappings ?? []}
      />
    </>
  );
}
