import React from 'react'
import Dialog from "../../components/Dialog";
import { Button } from '@material-ui/core';
import { TextField } from "../../components/TextField";
import { useFormik } from "formik";
import { useQuery, useMutation } from 'react-query';
import { doc, getDoc } from "firebase/firestore";
import { renameMapping } from "../../domain/mappings";
import { firestore } from "../../firebase";

export default function RenameMappingDialog({ mappingId, onSuccess, onError, open, onClose }) {
  const renameMutation = useMutation(async (values) => {
    try {
      await renameMapping(mappingId, values.name);
      await onSuccess()
    } catch (e) {
      onError(e)
    }
  });

  const formik = useFormik({
    onSubmit: (values) => {
      if (!values.name.length) {
        return onClose()
      }

      renameMutation.mutate(values)
    },
    initialValues: {
      name: '',
    },
  });

  useQuery([`mapping-${mappingId}`], async () => {
    const docSnapshot = await getDoc(
      doc(firestore, "mappings", mappingId)
    );

    const m = { id: docSnapshot.id, ...docSnapshot.data() }

    formik.setFieldValue('name', m.name);

    return m;
  });

  return (
    <Dialog
      title="Rename"
      fullWidth
      maxWidth="sm"
      open={open}
      actions={
        <>
          <Button
            disabled={renameMutation.isLoading}
            variant="contained"
            color="primary"
            onClick={async () => {
              formik.handleSubmit();
            }}
          >
            Rename
          </Button>
          <Button
            disabled={renameMutation.isLoading}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
        </>
      }
    >
      <TextField
        disabled={renameMutation.isLoading}
        fullWidth
        name="name"
        label="Name"
        value={formik.values.name}
        onChange={formik.handleChange}
      />
    </Dialog>
  );
}
